import { template as template_52c4705594bb42709e85182a3d30c87b } from "@ember/template-compiler";
const FKControlMenuContainer = template_52c4705594bb42709e85182a3d30c87b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
