import { template as template_4e97ac0c3fa64ea4a3e290481dc9ce09 } from "@ember/template-compiler";
const FKLabel = template_4e97ac0c3fa64ea4a3e290481dc9ce09(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
