import { template as template_620653f482d64439a00ffb9197301061 } from "@ember/template-compiler";
const WelcomeHeader = template_620653f482d64439a00ffb9197301061(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
