import { template as template_e88df7a132b94c82827d0f5954eb0475 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e88df7a132b94c82827d0f5954eb0475(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
