import { template as template_2464012f79094369a750961e97b20c82 } from "@ember/template-compiler";
const FKText = template_2464012f79094369a750961e97b20c82(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
